import React from 'react';

import { IFooterFields } from '../../../@generated/@types/contentful';

import styles from './index.module.css';
import { InspireLink } from '../../atoms/link';
import { InspireCmsEntry } from '../../../common/types';

export default function SecondaryBlockLinkBlocks(props: { footer: InspireCmsEntry<IFooterFields> }): JSX.Element {
    const secondaryBlockLinks = props.footer.fields.secondaryBlock.fields.links;

    return (
        <section className={styles.secondaryBlockLinksContainer}>
            <ul className={styles.secondaryBlockLinksList}>
                {secondaryBlockLinks.map((link) => {
                    return (
                        <li key={link.sys.id}>
                            <InspireLink type="secondary" link={link} />
                        </li>
                    );
                })}
            </ul>
        </section>
    );
}
