import React from 'react';

import { IFooterFields } from '../../../@generated/@types/contentful';

import styles from './index.module.css';
import ContentfulImage from '../../atoms/ContentfulImage';
import { InspireCmsEntry } from '../../../common/types';

export default function IconBar(props: { footer: InspireCmsEntry<IFooterFields> }): JSX.Element {
    return (
        <section className={styles.footerIconBar}>
            {props?.footer?.fields?.footerIcons?.map((icon) => (
                <ContentfulImage key={icon.sys.id} asset={icon} imageAlt="" maxWidth={200} />
            ))}
        </section>
    );
}
