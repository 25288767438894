import React from 'react';
import classnames from 'classnames';

import { IFooterFields } from '../../../@generated/@types/contentful';

import styles from './index.module.css';
import { InspireCmsEntry } from '../../../common/types';

export default function SecondaryBlockHintBar(props: { footer: InspireCmsEntry<IFooterFields> }): JSX.Element {
    let label = props.footer.fields.secondaryBlock.fields.label;

    if (process.env.NEXT_PUBLIC_ARTIFACT_VERSION) {
        label = `${label} v.${process.env.NEXT_PUBLIC_ARTIFACT_VERSION.split('-')[0]}`;
    }

    return (
        <section className={classnames('t-paragraph-hint', styles.secondaryBlockHintContainer)}>
            <span>{label}</span>
        </section>
    );
}
