import React from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import { IFooterFields } from '../../../@generated/@types/contentful';

import Icon from '../../atoms/BrandIcon';
import { InspireLink } from '../../atoms/link';

import styles from './index.module.css';

import { getLinkDetails } from '../../../lib/link';
import useGlobalProps from '../../../redux/hooks/useGlobalProps';
import ContentfulImage from '../../atoms/ContentfulImage';
import { InspireCmsEntry } from '../../../common/types';
import { ICON_VARIANT } from './constants';

export default function LinkBlocks(props: { footer: InspireCmsEntry<IFooterFields> }): JSX.Element {
    const { productDetailsPagePaths } = useGlobalProps();
    const { width: backgroundImageWidth, height: backgroundImageHeight } =
        props?.footer?.fields?.backgroundImage?.fields?.file?.details?.image || {};
    return (
        <div className={styles.linkBlocksContainer}>
            {props?.footer?.fields?.linksBlocks?.map((linkBlock) => (
                <section key={linkBlock.sys.id} className={styles.linkBlock}>
                    <div className={classnames('t-subheader-universal-small', styles.linkBlockHeading)}>
                        {linkBlock.fields?.title}
                    </div>
                    <ul className={classnames('list-unordered-links', styles.linkBlockList)}>
                        {linkBlock?.fields?.links
                            ?.filter((link) => !!link.fields)
                            .map((link) => {
                                const { isExternal, name } = getLinkDetails(link, {
                                    productDetailsPagePaths,
                                });

                                return (
                                    <li key={link.sys.id}>
                                        <InspireLink
                                            link={link}
                                            className={classnames('link-secondary-active', styles.link)}
                                        >
                                            <span>{name}</span>
                                            {isExternal && (
                                                <Icon
                                                    className={styles.externalLinkIcon}
                                                    variant={ICON_VARIANT}
                                                    icon="action-open-in-new"
                                                    size="xs"
                                                />
                                            )}
                                        </InspireLink>
                                    </li>
                                );
                            })}
                    </ul>
                </section>
            ))}
            <section className={styles.linkBlock}>
                <div
                    className={classnames(
                        't-subheader-universal-small',
                        styles.linkBlockHeading,
                        styles.linkBlockHeadingDesktop
                    )}
                >
                    {props?.footer?.fields?.socialMediaLinksBlock?.fields.title}
                </div>
                <div
                    className={classnames(
                        't-subheader-universal-small',
                        styles.linkBlockHeading,
                        styles.linkBlockHeadingMobile
                    )}
                >
                    {props?.footer?.fields?.socialMediaLinksBlock?.fields.mobileTitle ||
                        props?.footer?.fields?.socialMediaLinksBlock?.fields.title}
                </div>
                <div className={styles.socialLinkBlockList}>
                    {props?.footer?.fields?.socialMediaLinksBlock?.fields?.socialMediaLinks?.map((link) => {
                        return (
                            <span key={link.sys.id}>
                                {link.fields.url && (
                                    <Link href={link.fields.url}>
                                        <a target="_blank" rel="noreferrer">
                                            <ContentfulImage
                                                asset={link?.fields?.icon}
                                                maxWidth={50}
                                                width={45}
                                                height={45}
                                            />
                                        </a>
                                    </Link>
                                )}
                            </span>
                        );
                    })}
                </div>
            </section>
            <ContentfulImage
                asset={props?.footer?.fields?.backgroundImage}
                className={styles.backgroundImage}
                imageAlt=""
                width={backgroundImageWidth}
                height={backgroundImageHeight}
            />
        </div>
    );
}
