import React from 'react';
import classNames from 'classnames';

import { IFooterFields } from '../../../@generated/@types/contentful';

import styles from './index.module.css';

import IconBar from './iconBar';
import LinkBlocks from './linkBlocks';
import SecondaryBlockLinkBlocks from './secondaryBlockLinkBlocks';
import SecondaryBlockHintBar from './secondaryBlockHintBar';
import { InspireCmsEntry } from '../../../common/types';
import { useScreen } from '../../../common/hooks/useScreen';

export default function Footer(props: { footer: InspireCmsEntry<IFooterFields>; className?: string }): JSX.Element {
    const { footer, className } = props;

    const showIconBar = footer.fields?.footerIcons?.length;

    const { isDesktop } = useScreen();

    return !footer.fields.hidden ? (
        <footer className={classNames(styles.footer, className)}>
            {showIconBar && <IconBar footer={footer} />}

            <section className={classNames(styles.footerContent, { [styles.withoutIconBar]: !showIconBar })}>
                <LinkBlocks footer={footer} />

                <div className={styles.footerSecondaryContainer}>
                    {isDesktop ? (
                        <>
                            <SecondaryBlockHintBar footer={footer} />
                            <SecondaryBlockLinkBlocks footer={footer} />
                        </>
                    ) : (
                        <>
                            <SecondaryBlockLinkBlocks footer={footer} />
                            <SecondaryBlockHintBar footer={footer} />
                        </>
                    )}
                </div>
            </section>
        </footer>
    ) : null;
}
